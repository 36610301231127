<template>
  <div
    class="px-0 container-fluid h-100"
    style="text-align: left"
  >
    <div
      class="container"
      style="padding-top: 30px"
    >
      <BreadCrumbComponent :pageTitles="['Our Teams', `${category.name?category.name:'...'}`]" />
    </div>
    <article>
      <section style="padding-top: 30px">
        <div class="container">
          <div class="row w-100">
            <div class="text-left col-12">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;">
                <strong>{{ category.name?category.name:'...' }}</strong><br />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div style="margin-top: 15px;">
                <el-input
                  placeholder="Search by Name..."
                  v-model="search"
                  class="input-with-select"
                >
                </el-input>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
    </article>

    <section v-loading="isFetching || deleting">

      <!--- SHOW THIS ARTICLE WHEN FETCHING TEAMS FROM THE DATABASE HAS FAILED --->
      <article
        v-if="isLoadingError"
        class="d-flex align-items-center justify-content-center"
        style="height: 180px"
      >
        <div class="text-center">
          <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
          <div
            class="pt-2"
            style="font-weight: 400; font-size: 0.9em"
          >
            Failed to fetch Teams now. Please try again
          </div>
          <button
            type="button"
            class="mx-0 mt-3 btn z-depth-0"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click="getTeams"
            style="
          width: 220px;
          text-transform: capitalize;
          height: 45px;
          border-radius: 4px;
          background-color: red;
          font-weight: 500;
        "
          >
            <span style="position: relative; bottom: 1px"><i class="pr-2 fas fa-refresh"></i> Try Again</span>
          </button>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN STILL FETCHING TEAMS FROM THE SERVER --->
      <article
        v-show="isFetching && !isLoadingError"
        class=""
      >
        <div class="container">
          <div class="our_teams_grid">
            <div
              v-for="i in [1, 2, 3, 4, 5, 6, 7, 8]"
              :key="i"
              class="skeleton-block skeleton-effect-wave"
              style="height: 300px; border-radius: 5px"
            ></div>
          </div>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN TEAMS ARE FETCHED FROM THE SERVER --->
      <article v-if="!isFetching && !isLoadingError">
        <div class="container">
          <div class="our_teams_grid">
            <div
              class="our_team_container"
              v-for="(member, index) in filteredMembers"
              :key="index"
            >
              <div class="our_team_container_content">
                <div>
                  <div class="publish_content">
                    <img
                      :src="member.image_path_url"
                      style="width: 100%; height: 100%; object-fit: cover;"
                    >
                  </div>
                  <div class="publishing_status">
                    <el-button
                      :type="member.is_published == true ?'success' : 'danger'"
                      @click="changePublishStatus(member.is_published, member.website_team_member_id)"
                      size="mini"
                    >
                      {{ member.is_published == true ?'Published' : 'Not Published' }}
                    </el-button>
                  </div>
                </div>
                <div class="p-2">
                  <div>
                    <span style="font-size: 1em; font-weight: 600; text-transform: uppercase;">
                      {{ member.first_name }}
                      {{ member.last_name }}
                    </span><br />
                    <small style="font-size: 0.8em; font-weight: 500; color: #555555; text-transform: uppercase;">
                      {{ member.position }}
                    </small>
                  </div>
                  <div>
                    <small style="color: grey;">
                      {{ member.short_description }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="our_team_buttons">
                <el-tooltip content="Move up"
                  placement="top">
                  <el-button type="info"
                    icon="el-icon-top"
                    circle
                    size="mini"
                    :disabled="member.orderIndex == 0"
                    @click="moveTeamMemberUp(member.website_team_member_id, member.orderIndex)"></el-button>
                </el-tooltip>
                <el-tooltip content="Move Down"
                  placement="top">
                  <el-button type="info"
                  icon="el-icon-bottom"
                  size="mini"
                  circle
                  :disabled="member.orderIndex == (team_members.length - 1)"
                  @click="moveTeamMemberDown(member.website_team_member_id, member.orderIndex)"></el-button>
                </el-tooltip>
                <el-button
                  type="warning"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editTeamMember(member.website_team_member_id)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="deleteTeamMember(member.website_team_member_id)"
                ></el-button>
              </div>
            </div>
            <AddTeamMemberDialog
              :teamId="$route.params.teamId"
              @on-refresh="getTeamMembers"
            />
          </div>
        </div>
      </article>
    </section>

    <EditTeamMemberDialog
      :isEditTeamMemberDialogVisible="isEditTeamMemberDialogVisible"
      :teamMemberId="website_team_member_id"
      @on-refresh="getTeamMembers"
      @on-close-dialog="closeEditTeamMemberDialog"
    />
  </div>
</template>

<script>
import BreadCrumbComponent from "../components/bread-crumb-component";
import AddTeamMemberDialog from "../components/dialogs/add-team-member-dialog.vue";
import EditTeamMemberDialog from "../components/dialogs/edit-team-member-dialog.vue";

export default {
  components: {
    AddTeamMemberDialog,
    EditTeamMemberDialog,
    BreadCrumbComponent,
  },

  data() {
    return {
      isFetching: true,
      isLoadingError: false,
      fileErrorMessage: "",
      StaffPassword: "",
      showPasswordBool: false,
      isEditTeamMemberDialogVisible: false,
      website_team_member_id: "",
      search: "",

      loading: false,
      submiting: false,
      loadingError: false,
      deleting: false,
      team_members: [],
      category: {},
    };
  },

  computed: {
    filteredMembers: function () {
      return this.team_members.filter((member) => {
        return member.first_name.match(new RegExp(this.search, "i"));
      });
    },
  },

  mounted() {
    this.getTeamMembers();
  },

  methods: {
    editTeamMember(website_team_member_id) {
      this.isEditTeamMemberDialogVisible = true;
      this.website_team_member_id = website_team_member_id;
    },

    closeEditTeamMemberDialog() {
      this.isEditTeamMemberDialogVisible = false;
    },

    async getTeamMembers() {
      try {
        this.isFetching = true;
        this.isLoadingError = false;
        let request = await this.$http.get(
          `website/our-teams/categories/${this.$route.params.teamId}/members`
        );
        if (
          request.data.success &&
          request.data.message == "TEAM MEMBERS FETCHED SUCCESSFULLY"
        ) {
          this.team_members = request.data.team_members.map((item, index) => {
            return {
              website_team_member_id: item.website_team_member_id,
              first_name: item.first_name,
              last_name: item.last_name,
              position: item.position,
              short_description: item.short_description,
              image_path_url: item.image_path_url,
              is_published: item.is_published,
              orderIndex: index,
            }
          });
          this.category = request.data.category;
          this.isFetching = false;
          this.isLoadingError = false;
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.isFetching = false;
          this.isLoadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "CATEGORY NOT FOUND") {
            this.isFetching = false;
            this.isLoadingError = true;
            return this.showWarningMessage(
              "Not Found",
              "Unable to find this Category"
            );
          }
        } else {
          this.isFetching = false;
          this.isLoadingError = true;
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.isFetching = false;
        this.isLoadingError = true;
        this.showFailedMessage("Operation Failed", "Unable to Fetch Category");
      } finally {
        //
      }
    },

    async deleteTeamMember(website_team_member_id) {
      await this.$confirm(
        "This will permanently delete this Team Member. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.deleting = true;
        let request = await this.$http.delete(
          `website/our-teams/members/${website_team_member_id}`
        );
        if (
          request.data.success &&
          request.data.message == "TEAM MEMBER DELETED SUCCESSFULLY"
        ) {
          this.getTeamMembers();
          this.deleting = false;
          return this.showSuccessMessage(
            "Deleted Successfully",
            "The Team Member was successfully deleted"
          );
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "MEMBER DOES NOT EXIST") {
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the specified Member"
            );
          }
        } else {
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.showFailedMessage("Operation Failed", "Unable to delete Member");
      } finally {
        this.deleting = false;
      }
    },

    async changePublishStatus(is_published, website_team_member_id) {
      await this.$confirm(
        `Are you sure you want to change the publish status?`,
        "Confirm Changes",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.isBusy = true;
        let request = await this.$http.patch(
          `website/our-teams/members/${website_team_member_id}/toggle-publish`
        );
        if (
          request.data.success &&
          request.data.message ==
            "TEAM MEMBER PUBLISHED STATUS TOGGLED SUCCESSFULLY"
        ) {
          this.getTeamMembers();
          return this.showSuccessMessage(
            "Changes Saved Successfully",
            "The Publish Status was Successfully changed"
          );
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "CATEGORY NOT FOUND") {
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the chosen Category"
            );
          }
        } else {
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.showFailedMessage(
          "Operation Failed",
          "Unable To Change Category Publish Status"
        );
      } finally {
        this.isBusy = false;
      }
    },

        
    moveTeamMemberUp(teamMemberId, teamMemberOrderIndex) {
      // get previous member as we want to swap with it
      const affectedTeamMember = this.team_members.filter(item => item.website_team_member_id == teamMemberId)[0];
      const currentIndex = affectedTeamMember.orderIndex;

      const prevTeamMember = this.team_members.filter(item => item.orderIndex == (teamMemberOrderIndex - 1))[0]

      // swap with previous member
      affectedTeamMember.orderIndex = prevTeamMember.orderIndex
      prevTeamMember.orderIndex = currentIndex

      this.saveTeamMembersOrder()
    },

    moveTeamMemberDown(teamMemberId, teamMemberOrderIndex) {
      // get previous member as we want to swap with it
      const affectedTeamMember = this.team_members.filter(item => item.website_team_member_id == teamMemberId)[0];
      const currentIndex = affectedTeamMember.orderIndex;

      const prevTeamMember = this.team_members.filter(item => item.orderIndex == (teamMemberOrderIndex + 1))[0]

      // swap with previous member
      affectedTeamMember.orderIndex = prevTeamMember.orderIndex
      prevTeamMember.orderIndex = currentIndex

      this.saveTeamMembersOrder()
    },

    async saveTeamMembersOrder() {
      let request = await this.httpRequest({
        method: "PATCH",
        url: "website/save-order-indexes",
        loadingPropertyName: "isFetching",
        errorLoadingPropertyName: "isLoadingError",
        showSuccessMessage: true,
        body: {
          itemType: "WEBSITE_TEAM_MEMBERS",
          items: this.team_members.map(item => {
            return {
              id: item.website_team_member_id,
              index: item.orderIndex
            }
          })
        },
      });

      if (
        request &&
        request.success &&
        request.message == "Items order saved successfully"
      ) {
        this.getTeamMembers();
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.to-an-array {
  display: flex;
  align-items: center;
}

.to-an-array > div {
  margin-right: 20px;
}

.our_teams_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.our_teams_grid > div {
  /* height: 300px; */
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.our_teams_grid > .our_team_container {
  position: relative;
}

.our_teams_grid > .our_team_container .our_team_buttons {
  display: none;
}

.our_teams_grid > .our_team_container:hover .our_team_buttons {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
}

.our_team_buttons .el-icon-edit {
  font-size: 1.5em;
  margin-right: 10px;
  color: orange;
  cursor: pointer;
}
.our_team_buttons .el-icon-delete {
  font-size: 1.5em;
  color: red;
  cursor: pointer;
}

.our_teams_grid > .our_team_container .our_team_container_content {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.our_teams_grid > .our_team_container .our_team_container_content > div {
  width: 100%;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  > div:first-child {
  border-radius: 5px 5px 0px 0px;
  height: 200px;
  background-color: #2a70b5a0;
  color: white;
  position: relative;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  .publish_content {
  height: 100%;
  width: 100%;
  font-size: 3em;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  .publish_content
  img {
  border-radius: 5px 5px 0px 0px;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  .publishing_status {
  position: absolute;
  bottom: 0px;
  right: 0px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  > div:nth-child(2) {
  /* text-align: center; */
  background-color: white;
  color: black;
  padding-top: 20px 20px;
  border-radius: 0px 0px 5px 5px;
  /* height: 100px; */
}

.fd_chosen {
  display: block;
}

.fd_Notchosen {
  display: none;
}

.fd_faculty,
.fd_dept {
  display: block;
}

.fd_Notfaculty,
.fd_Notdept {
  display: none;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .our_teams_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .our_teams_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
</style>

<style>
.publishing_status .el-button {
  border-radius: 5px 0px 0px 5px;
}
</style>