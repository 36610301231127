<template>
  <div>
    <section>
      <div class="container">
        <div class="team_dialogs">
          <el-dialog
            title="Edit Team"
            :visible.sync="editTeamModal"
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div v-loading="submitting || isLoading">
              <el-form
                :model="teamForm"
                label-position="top"
                :rules="teamRules"
                ref="teamForm"
                label-width="120px"
                class="demo-teamForm"
                onSubmit="return false;"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item
                      label="Name"
                      prop="name"
                    >
                      <el-input
                        v-model="teamForm.name"
                        @keyup.enter.native="editTeam('teamForm')"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="editTeam('teamForm')"
                  >Edit Team</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small></small>
                </div>
              </div>

            </div>
          </el-dialog>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  props: {
    website_team_category_id: {
      type: String,
      required: true,
    },
    isEditTeamDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editTeamModal: false,
      submitting: false,
      isLoading: false,
      teamForm: {
        name: "",
      },
      teamRules: {
        name: [
          {
            required: true,
            message: "Name is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  watch: {
    isEditTeamDialogVisible() {
      if (this.isEditTeamDialogVisible == true) {
        this.getOneTeam(this.website_team_category_id);
        this.editTeamModal = true;
      } else {
        this.editTeamModal = false;
      }
    },
  },

  methods: {
    openTeamEditModal() {
      this.editTeamModal = true;
    },
    handleClose(done) {
      done();
      this.$emit("on-close-dialog");
    },
    async editTeam(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.submitting = true;
          try {
            const response = await this.$http.patch(
              `website/our-teams/categories/${this.website_team.website_team_category_id}`,
              {
                name: this.teamForm.name,
              }
            );
            if (
              response.data.success == true &&
              response.data.message == "CATEGORY UPDATED SUCCESSFULLY"
            ) {
              this.editTeamModal = false;
              this.$emit("on-refresh");
              this.$emit("on-close-dialog");
              this.submitting = false;
              return this.showSuccessMessage(
                "Category Updated",
                "The category was successfully updated"
              );
            } else {
              throw "UNEXPECTED API RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.showFailedMessage(
                "Connection failed",
                "A network error occurred, please try again."
              );
            } else if (error.response) {
              if (
                error.response.data.message == "CATEGORY NAME ALREADY IN USE"
              ) {
                return this.showWarningMessage(
                  "Already in use",
                  "Another Category is using the same name as the one specified"
                );
              }
            } else {
              return this.showFailedMessage(
                "Unexpected Error",
                "An unexpected error occurred. Please try again"
              );
            }
            this.submitting = false;
            this.showFailedMessage("Operation failed", "Unable to Update Team");
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async getOneTeam(website_team_category_id) {
      try {
        this.isLoading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `website/our-teams/categories/${website_team_category_id}`
        );
        if (
          request.data.success &&
          request.data.message == "CATEGORY FETCHED SUCCESSFULLY"
        ) {
          this.website_team = request.data.category;
          this.teamForm.name = this.website_team.name;
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "CATEGORY DOES NOT EXIST") {
            this.loadingError = true;
            this.$emit("on-refresh");
            this.$emit("on-close-dialog");
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the specified category"
            );
          }
        } else {
          this.loadingError = true;
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.showFailedMessage("Operation Failed", "Unable to Fetch Category");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.addDialogTrigger {
  border: 1px dashed black;
  border-radius: 5px;
  height: 100%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.team_dialogs .el-dialog {
  width: 40%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .team_dialogs .el-dialog {
    width: 50%;
  }
}
/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .team_dialogs .el-dialog {
    width: 70%;
  }
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}
</style>