<template>
  <div
    v-loading="isBusy || deleting"
    class="px-0 container-fluid h-100"
    style="text-align: left"
  >
    <div class="container mt-3">
      <BreadCrumbComponent :pageTitles="['Our Teams']" />
    </div>
    <article class="mt-3">
      <section>
        <div class="container">
          <div class="row w-100">
            <div class="text-left col-12">
              <div style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;">
                <strong>Our Teams</strong><br />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div style="margin-top: 15px;">
                <el-input
                  placeholder="Search by Title..."
                  v-model="search"
                  class="input-with-select"
                >
                </el-input>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
    </article>

    <section>

      <!--- SHOW THIS ARTICLE WHEN FETCHING TEAMS FROM THE DATABASE HAS FAILED --->
      <article
        v-if="isLoadingError"
        class="d-flex align-items-center justify-content-center"
        style="height: 180px"
      >
        <div class="text-center">
          <div style="font-weight: 600; margin-top: 50px">Fetching Failed</div>
          <div
            class="pt-2"
            style="font-weight: 400; font-size: 0.9em"
          >
            Failed to fetch Teams now. Please try again
          </div>
          <button
            type="button"
            class="mx-0 mt-3 btn z-depth-0"
            v-ripple="'rgba(255, 255, 255, 0.35)'"
            @click="getTeams"
            style="
          width: 220px;
          text-transform: capitalize;
          height: 45px;
          border-radius: 4px;
          background-color: red;
          font-weight: 500;
        "
          >
            <span style="position: relative; bottom: 1px"><i class="pr-2 fas fa-refresh"></i> Try Again</span>
          </button>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN STILL FETCHING TEAMS FROM THE SERVER --->
      <article
        v-show="isFetching && !isLoadingError"
        class=""
      >
        <div class="container">
          <div class="our_teams_grid">
            <div
              v-for="i in [1, 2, 3, 4, 5, 6, 7, 8]"
              :key="i"
              class="skeleton-block skeleton-effect-wave"
              style="height: 300px; border-radius: 5px"
            ></div>
          </div>
        </div>
      </article>

      <!--- SHOW THIS ARTICLE WHEN TEAMS ARE FETCHED FROM THE SERVER --->
      <article v-if="!isFetching && !isLoadingError">
        <div class="container">
          <div class="our_teams_grid">
            <div
              v-for="(team, index) in filteredTeams"
              :key="index"
              class="our_team_container"
            >
              <div class="our_team_container_content">
                <div>
                  <div class="publish_content">
                    <span class="el-icon-folder-opened"></span>
                  </div>
                  <div class="publishing_status">
                    <el-button
                      :type="team.is_published == true ?'success' : 'danger'"
                      @click="changePublishStatus(team.is_published, team.website_team_category_id)"
                      size="mini"
                    >
                      {{ team.is_published == true ?'Published' : 'Not Published' }}
                    </el-button>
                  </div>
                </div>
                <div>
                  <div>
                    <p>{{ team.name }}</p>
                  </div>
                  <div>
                    <el-button
                      type="primary"
                      round
                      size="mini"
                      class="pl-4 pr-4"
                      @click="$router.push({name: `TeamDetails`, params: {teamId: `${team.website_team_category_id}`} })"
                      style="cursor: pointer;"
                    >Members</el-button>
                  </div>
                </div>
              </div>
              <div class="our_team_buttons">
                <el-tooltip content="Move up"
                  placement="top">
                  <el-button type="info"
                    icon="el-icon-top"
                    circle
                    size="mini"
                    :disabled="team.orderIndex == 0"
                    @click="moveTeamUp(team.website_team_category_id, team.orderIndex)"></el-button>
                </el-tooltip>
                <el-tooltip content="Move Down"
                  placement="top">
                  <el-button type="info"
                  icon="el-icon-bottom"
                  size="mini"
                  circle
                  :disabled="team.orderIndex == (teams.length - 1)"
                  @click="moveTeamDown(team.website_team_category_id, team.orderIndex)"></el-button>
                </el-tooltip>
                <el-button
                  type="warning"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editTeam(team.website_team_category_id)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="deleteTeam(team.website_team_category_id)"
                ></el-button>
              </div>
            </div>
            <AddTeamDialog @on-refresh="getTeams" />
          </div>
        </div>
      </article>
    </section>

    <EditTeamDialog
      :isEditTeamDialogVisible="isEditTeamDialogVisible"
      :website_team_category_id="website_team_category_id"
      @on-refresh="getTeams"
      @on-close-dialog="closeEditTeamDialog"
    />
  </div>
</template>

<script>
// import ScaleOut from "@/components/scale-out-component.vue";
import BreadCrumbComponent from "../components/bread-crumb-component";
import AddTeamDialog from "../components/dialogs/add-team-dialog.vue";
import EditTeamDialog from "../components/dialogs/edit-team-dialog.vue";

export default {
  components: {
    AddTeamDialog,
    EditTeamDialog,
    BreadCrumbComponent,
  },

  data() {
    return {
      isFetching: true,
      isLoadingError: false,
      isBusy: false,
      search: "",
      isEditTeamDialogVisible: false,
      website_team_category_id: "",
      submitting: false,
      deleting: false,
      teams: [],
    };
  },

  computed: {
    filteredTeams: function () {
      return this.teams.filter((team) => {
        return team.name.match(new RegExp(this.search, "i"));
      });
    },
  },

  mounted() {
    this.getTeams();
  },

  methods: {
    async getTeams() {
      try {
        this.isFetching = true;
        this.isLoadingError = false;
        let request = await this.$http.get(`website/our-teams/categories`);
        if (
          request.data.success &&
          request.data.message == "CATEGORIES FETCHED SUCCESSFULLY"
        ) {
          this.teams = request.data.categories.map((item, index) => {
            return {
              website_team_category_id: item.website_team_category_id,
              name: item.name,
              is_published: item.is_published,
              orderIndex: index,
            }
          });
          this.isFetching = false;
          this.isLoadingError = false;
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.isFetching = false;
          this.isLoadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "CATEGORY DOES NOT EXIST") {
            this.isFetching = false;
            this.isLoadingError = true;
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the specified category"
            );
          }
        } else {
          this.isFetching = false;
          this.isLoadingError = true;
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.isFetching = false;
        this.isLoadingError = true;
        return this.showFailedMessage(
          "Unable to Fetch Teams",
          "An unexpected Error occurred, please try again"
        );
      } finally {
        //
      }
    },

    editTeam(website_team_category_id) {
      this.website_team_category_id = website_team_category_id;
      this.isEditTeamDialogVisible = true;
    },

    closeEditTeamDialog() {
      this.isEditTeamDialogVisible = false;
    },

    async deleteTeam(website_team_category_id) {
      await this.$confirm(
        "This will permanently delete this Team. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.deleting = true;
        let request = await this.$http.delete(
          `website/our-teams/categories/${website_team_category_id}`
        );
        if (
          request.data.success &&
          request.data.message == "CATEGORY DELETED SUCCESSFULLY"
        ) {
          this.getTeams();
          this.deleting = false;
          return this.showSuccessMessage(
            "Deleted Successfully",
            "The Category was successfully deleted"
          );
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "CATEGORY DOES NOT EXIST") {
            this.getTeams();
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the specified category"
            );
          }
        } else {
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.showFailedMessage("Operation Failed", "Unable to delete Team.");
      } finally {
        this.deleting = false;
      }
    },

    async changePublishStatus(is_published, website_team_category_id) {
      await this.$confirm(
        `Are you sure you want to change the publish status?`,
        "Confirm Changes",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      );
      try {
        this.isBusy = true;
        let request = await this.$http.patch(
          `website/our-teams/categories/${website_team_category_id}/toggle-publish`
        );
        if (
          request.data.success &&
          request.data.message ==
            "CATEGORY PUBLISHED STATUS TOGGLED SUCCESSFULLY"
        ) {
          this.getTeams();
          this.isBusy = false;
          return this.showSuccessMessage(
            "Changes Saved Successfully",
            "The Publish Status was successfully changed"
          );
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "CATEGORY NOT FOUND") {
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the chosen category"
            );
          }
        } else {
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.showFailedMessage(
          "Operation Failed",
          "Unable To Change Category Publish Status"
        );
      } finally {
        this.isBusy = false;
      }
    },

    
    moveTeamUp(teamId, teamOrderIndex) {
      // get previous team as we want to swap with it
      const affectedTeam = this.teams.filter(item => item.website_team_category_id == teamId)[0];
      const currentIndex = affectedTeam.orderIndex;

      const prevTeam = this.teams.filter(item => item.orderIndex == (teamOrderIndex - 1))[0]

      // swap with previous team
      affectedTeam.orderIndex = prevTeam.orderIndex
      prevTeam.orderIndex = currentIndex

      this.saveTeamCategoriesOrder()
    },

    moveTeamDown(teamId, teamOrderIndex) {
      // get previous team as we want to swap with it
      const affectedTeam = this.teams.filter(item => item.website_team_category_id == teamId)[0];
      const currentIndex = affectedTeam.orderIndex;

      const prevTeam = this.teams.filter(item => item.orderIndex == (teamOrderIndex + 1))[0]

      // swap with previous team
      affectedTeam.orderIndex = prevTeam.orderIndex
      prevTeam.orderIndex = currentIndex

      this.saveTeamCategoriesOrder()
    },

    async saveTeamCategoriesOrder() {
      let request = await this.httpRequest({
        method: "PATCH",
        url: "website/save-order-indexes",
        loadingPropertyName: "isBusy",
        errorLoadingPropertyName: "isLoadingError",
        showSuccessMessage: true,
        body: {
          itemType: "WEBSITE_TEAM_CATEGORIES",
          items: this.teams.map(item => {
            return {
              id: item.website_team_category_id,
              index: item.orderIndex
            }
          })
        },
      });

      if (
        request &&
        request.success &&
        request.message == "Items order saved successfully"
      ) {
        this.getTeams();
      }
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.our_teams_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.our_teams_grid > div {
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  background-color: white;
}

.our_teams_grid > .our_team_container {
  position: relative;
}

.our_teams_grid > .our_team_container .our_team_buttons {
  display: none;
}

.our_teams_grid > .our_team_container:hover .our_team_buttons {
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px;
}

.our_team_buttons .el-icon-edit {
  font-size: 1.5em;
  margin-right: 10px;
  color: orange;
  cursor: pointer;
}
.our_team_buttons .el-icon-delete {
  font-size: 1.5em;
  color: red;
  cursor: pointer;
}

.our_teams_grid > .our_team_container .our_team_container_content {
  border-radius: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.our_teams_grid > .our_team_container .our_team_container_content > div {
  width: 100%;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  > div:first-child {
  border-radius: 5px 5px 0px 0px;
  height: 200px !important;
  background-color: #2a70b5a0;
  color: white;
  position: relative;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  .publish_content {
  height: 200px;
  width: 100%;
  font-size: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  .publishing_status {
  position: absolute;
  bottom: 0px;
  right: 0px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.our_teams_grid
  > .our_team_container
  .our_team_container_content
  > div:nth-child(2) {
  text-align: center;
  background-color: white;
  color: black;
  padding-top: 20px;
  border-radius: 0px 0px 5px 5px;
  height: 100%;
  font-size: 1em;
  font-weight: 400;
  padding-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .our_teams_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .our_teams_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}
</style>

<style>
.publishing_status .el-button {
  border-radius: 5px 0px 0px 5px;
}
</style>