<template>
  <div>
    <section>
      <div class="container">
        <div class="team_dialogs">
          <el-dialog
            title="Edit Member"
            :visible.sync="editTeamMemberModal"
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div v-loading="submitting || isUploadingFile || isLoading">
              <el-form
                :model="teamMemberForm"
                label-position="top"
                :rules="teamMemberRules"
                ref="teamMemberForm"
                class="demo-teamMemberForm"
                onSubmit="return false;"
              >
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item prop="first_name">
                      <label for="first_name">First Name</label>
                      <el-input
                        id="first_name"
                        maxlength="255"
                        v-model="teamMemberForm.first_name"
                        @keyup.enter.native="editTeamMember('teamMemberForm')"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item prop="last_name">
                      <label for="last_name">Last Name</label>
                      <el-input
                        id="last_name"
                        maxlength="255"
                        v-model="teamMemberForm.last_name"
                        @keyup.enter.native="editTeamMember('teamMemberForm')"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <el-form-item prop="position">
                      <label for="position">Position</label>
                      <el-input
                        id="position"
                        v-model="teamMemberForm.position"
                        @keyup.enter.native="editTeamMember('teamMemberForm')"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <el-form-item prop="short_description">
                      <label for="short_description">Short Description</label>
                      <el-input
                        id="short_description"
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="Please input"
                        maxlength="3000"
                        v-model="teamMemberForm.short_description"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 text-left">
                    <div
                      class="mb-3"
                      style="font-weight: 500; color: rgba(0, 0, 0, 0.8)"
                    >
                      Choose File
                    </div>
                    <el-upload
                      class="upload-demo"
                      drag
                      :action="''"
                      :file-list="fileLists"
                      :on-remove="handleRemove"
                      :on-change="handlePreview"
                      :auto-upload="false"
                      :multiple="false"
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </el-upload>
                  </div>
                </div>

                <el-form-item class="text-center mt-5">
                  <el-button
                    type="primary"
                    @click="editTeamMember('teamMemberForm')"
                  >Edit Member</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small></small>
                </div>
              </div>

            </div>
          </el-dialog>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  props: {
    teamMemberId: {
      type: String,
      required: true,
    },
    isEditTeamMemberDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editTeamMemberModal: false,
      submitting: false,
      isUploadingFile: false,
      isLoading: false,
      team_member: {},

      selectedFile: null,
      fileLists: [],
      teamMemberForm: {
        first_name: "",
        last_name: "",
        position: "",
        short_description: "",
      },
      teamMemberRules: {
        first_name: [
          {
            required: true,
            message: "First Name is required",
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: "Last Name is required",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            message: "Position is required",
            trigger: "blur",
          },
        ],
        short_description: [
          {
            required: true,
            message: "Short Description is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  watch: {
    isEditTeamMemberDialogVisible() {
      if (this.isEditTeamMemberDialogVisible == true) {
        this.getOneTeamMember(this.teamMemberId);
        this.editTeamMemberModal = true;
      } else {
        this.editTeamMemberModal = false;
      }
    },
  },

  methods: {
    handleClose(done) {
      done();
      this.$emit("on-close-dialog");
    },

    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file;
    },

    handleRemove() {
      this.selectedFile = null;
    },

    openTeamMemberModal() {
      this.editTeamMemberModal = true;
    },

    clearInputs() {
      this.teamMemberForm.first_name = "";
      this.teamMemberForm.last_name = "";
      this.teamMemberForm.position = "";
      this.teamMemberForm.short_description = "";
    },

    async getOneTeamMember(website_team_member_id) {
      try {
        this.isLoading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `website/our-teams/members/${website_team_member_id}`
        );
        if (
          request.data.success &&
          request.data.message == "TEAM MEMBER FETCHED SUCCESSFULLY"
        ) {
          this.team_member = request.data.team_member;
          this.teamMemberForm.first_name = this.team_member.first_name;
          this.teamMemberForm.last_name = this.team_member.last_name;
          this.teamMemberForm.position = this.team_member.position;
          this.teamMemberForm.short_description =
            this.team_member.short_description;

          this.selectedFile = this.team_member.image_path_url;
          this.fileLists = [
            {
              name: "Team Member Photo",
              url: `${this.team_member.image_path_url}`,
            },
          ];
        } else {
          throw "UNEXPECTED API RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (error.response.data.message === "TEAM MEMBER NOT FOUND") {
            this.loadingError = true;
            this.$emit("on-refresh");
            this.$emit("on-close-dialog");
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the selected person"
            );
          }
        } else {
          this.loadingError = true;
          return this.showFailedMessage(
            "Unexpected Error",
            "An unexpected error occurred. Please try again"
          );
        }
        this.showFailedMessage(
          "Operation Failed",
          "Unable to Fetch Team Member"
        );
      } finally {
        this.isLoading = false;
      }
    },

    async editTeamMember(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploadingFile = true;
          if (this.selectedFile == null) {
            this.isUploadingFile = false;
            return this.showWarningMessage(
              "Empty",
              "Team Member Photo is Empty"
            );
          }
          if (this.selectedFile.raw == undefined) {
            this.onAWSMemberUploaded(this.selectedFile);
          } else {
            this.requestForFileUploadUrl();
          }
        } else {
          return false;
        }
      });
    },

    async requestForFileUploadUrl() {
      try {
        this.isUploadingFile = true;
        let request = await this.$http.post(
          `website/request-for-file-upload-url`,
          {
            file_name: this.selectedFile.raw.name,
            file_size: this.selectedFile.raw.size,
            reason: "TEAM_MEMBERS",
          }
        );
        if (
          request.data.success &&
          request.data.message == "UPLOAD URL GENERATED SUCCESSFULLY"
        ) {
          this.aws_upload_url = request.data.upload_url;
          this.uploadMemberToAWS(this.aws_upload_url, this.selectedFile.raw);
        } else if (request.data.message == "UNSUPPORTED_FILE_TYPE") {
          return this.showWarningMessage(
            "Unsupported file",
            "The file you are trying to upload is not supported"
          );
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        if (error.message == "NetworkError") {
          this.isUploadingFile = false;
          return this.showWarningMessage(
            "Connection failed",
            "A network error occurred please try again"
          );
        }
        this.isUploadingFile = false;
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Team Member Photo now, Please try again"
        );
      }
    },
    // ___________________________________________________ uploading files
    async uploadMemberToAWS(aws_upload_url, selected_file) {
      this.isUploadingFile = true;
      try {
        const formData = new FormData();
        Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", selected_file);
        this.isUploadingFile = true;
        console.log(selected_file);
        let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: " ",
          },
          onUploadProgress: (progressEvent) => console.log(progressEvent),
        });

        if (request.status == 204) {
          console.log(request.headers.location);
          this.onAWSMemberUploaded(request.headers.location);
        } else throw "UNEXPECTED_RESPONSE";
      } catch (error) {
        console.log(error);
        if (error.message == "Network Error") {
          this.isUploadingFile = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.isUploadingFile = false;
        return this.showFailedMessage(
          "Upload Failed",
          "Unable to Upload Team Member Photo now, Please try again"
        );
      }
    },

    async onAWSMemberUploaded(profile_photo_path_url) {
      this.isUploadingFile = true;
      try {
        this.isUploadingFile = true;
        let request = await this.$http.patch(
          `website/our-teams/members/${this.teamMemberId}`,
          {
            first_name: this.teamMemberForm.first_name,
            last_name: this.teamMemberForm.last_name,
            position: this.teamMemberForm.position,
            short_description: this.teamMemberForm.short_description,
            image_path_url: decodeURIComponent(profile_photo_path_url),
          }
        );
        if (
          request.data.success &&
          request.data.message == "TEAM MEMBER UPDATED SUCCESSFULLY"
        ) {
          this.$emit("on-refresh");
          this.$emit("on-close-dialog");
          return this.showSuccessMessage(
            "Updated Successfully",
            "The Team member was successfully updated."
          );
        } else {
          throw "error occurred";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Operation Failed",
          "Unable to Update Team Member"
        );
      } finally {
        this.isUploadingFile = false;
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.addDialogTrigger {
  border: 1px dashed black;
  border-radius: 5px;
  height: 100%;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.team_dialogs .el-dialog {
  width: 40%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .team_dialogs .el-dialog {
    width: 50%;
  }
}
/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .team_dialogs .el-dialog {
    width: 70%;
  }
}
/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}
/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (max-width: 480px) {
  .team_dialogs .el-dialog {
    width: 90%;
  }
}
</style>